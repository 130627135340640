import { defineNuxtPlugin } from '#imports'
import { GraphQLClient } from 'graphql-request'
import { graphqlClientInjectionKey } from '~/composables/graphql'
import { getRequestHost } from 'h3'
import { useRequestHeaders } from '#app'

export default defineNuxtPlugin(nuxt => {
    let client: GraphQLClient
    if (process.server && nuxt.ssrContext) {
        const headers = useRequestHeaders()
        const event = nuxt.ssrContext.event
        client = new GraphQLClient(
            'http://' +
                getRequestHost(event).replace(/(:8080|:3000)/, ':8000') +
                '/graphql-public',
            { headers }
        )
    } else {
        client = new GraphQLClient(location.origin + '/graphql-public')
    }

    nuxt.vueApp.provide(graphqlClientInjectionKey, client)
})
